import React, { useState } from 'react';
import './500.scss';
import WordMark from '../Modules/Components/SVG/WordMark';
import Warning from '../Modules/Components/SVG/Warning';
import Loader from '../Modules/Components/Loader/Loader';


export default function Custom500() {
  const [loading, setLoading] = useState(false);
  return (
    <div
      className="page-not-found"
      style={{
        height: '100%',
        padding: 0,
        margin: 0,
        display: '-webkit-box',
        display: '-moz-box',
        display: '-ms-flexbox',
        display: '-webkit-flex',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Loader loading={loading} />
      <div
        className="page-not-found__center-box"
        style={{
          borderRadius: '20px',
          boxShadow: 'unset',
          background: '#fff',
          padding: '60px 30px',
          margin: '130px 30px 30px 30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          maxHeight: '450px',
          width: '100%',
          maxWidth: '500px',
          border: '2px solid #ebebeb'
        }}
      >
        <div className="page-not-found__center-box__top-section">
          <a href="/" onClick={() => setLoading(true)}><WordMark className="page-not-found__center-box__top-section__icons" /></a>
        </div>
        <div
          className="page-not-found__center-box__middle-section"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Warning
            className="page-not-found__center-box__middle-section__icons"
            width="46"
            height="50"
            style={{
              marginBottom: '15px'
            }}
          />
          <h1
            className="page-not-found__center-box__middle-section__message"
            style={{
              fontFamily: 'Inter-Bold',
              fontSize: '30px',
              fontWeight: '800',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.07',
              letterSpacing: 'normal',
              textTransform: 'uppercase',
              textAlign: 'center',
              marginBottom: '15px'
            }}
          >
            500 Server Side Error
          </h1>
          <p
            style={{
              fontFamily: 'Inter-Bold',
              fontSize: '15px',
              fontWeight: '800',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.07',
              letterSpacing: 'normal',
              textTransform: 'uppercase',
              textAlign: 'center',
              marginBottom: '15px'
            }}>
            An error 500 occurred on server
          </p>
        </div>
        <div className="page-not-found__center-box__bottom-section">
          <a href="/" onClick={() => setLoading(true)}>
            <button
              style={{
                fontFamily: 'Inter-Medium',
                fontSize: '16px',
                fontWeight: '400',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.5',
                letterSpacing: 'normal',
                color: '#fff',
                backgroundColor: '#1C21DF',
                border: 'none',
                padding: '20px 24px',
                borderRadius: '100px',
                cursor: 'pointer',
                boxSizing: 'border-box'
              }}
            >
              Go Back
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}