import React, { useState, useEffect } from 'react';
import WordMark from '../../Components/SVG/WordMark';
import BrokenPencil from '../../Components/SVG/BrokenPencil';
import ErrorForm from '../../Components/ErrorForm/Form';
import Loader from '../../Components/Loader/Loader';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ pageData, setActiveHeaderNav, setModalState, accountData, message = "404 Page Not Found", hideHeader = false, BrokenPencilShow = true , showReportButton = true , id = ""}) => {
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    if (typeof window !== 'undefined') {
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      //delete cookies for admin also
      document.cookie = 'admintoken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      document.cookie = 'admincontext=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      document.cookie = 'adminuser=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
      localStorage.clear();
      // sessionStorage.clear();
      setTimeout(() => {
          window.location.href = MAIN_CONFIG.URLS.LOGIN;
      },);
      // return false
  }
      // window.location.href = '/login'
  },[])
  return (

    <div className="page-not-found">
      <Loader loading={loading} />      
    </div >
  )
}
