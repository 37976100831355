/**
 * Adds commas to a long number.
 * @param {number} number
 * @returns {string}
 */
export default (number) => {
    const value = number != null ? number.toString() : '0'
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export let floatFormatter = (str) => {
    let newStr = ""
    let string = str?.toString();
    let splitStr = string?.split(".")

    if (string?.includes(".") && splitStr[1]?.length > 2) {
        newStr = splitStr[0] + "." + splitStr[1].slice(0, 2)
        return newStr
    } else if (string?.includes(".") && splitStr[1]?.length === 1) {
        newStr = splitStr[0] + "." + splitStr[1].slice(0, 1) + "0"
        return newStr
    } else {
        return str
    }
}