import React from 'react';
import AppPage from '../../Core/AppPage';
import CustomError from './CustomError';
import RedirectToLogin from '../RedirectToLogin';
const Custom404 = (props) => {
    let activeIframe = false
    const shouldRenderCustomError = !props.accountData;
    if (window.self !== window.top) {
        activeIframe = true
      }
    if (shouldRenderCustomError && !activeIframe) {
        return <RedirectToLogin {...props} />;
    }
    return <CustomError {...props} />;
};

const Custom404Page = AppPage(Custom404);

export default Custom404Page;
