import React, {useEffect,useState } from 'react';
import Loader from '../Loader/Loader';

/**
 *
 * @param {object} data
 * @param {function} handleClose
 * @param {function} handleSave
 * @param {function} handleAdd
 * @param {function} handleRemove
 * @returns {JSX.Element}
 */
export default ({
    url = ''
}) => {
    const [isLoading,setIsLoading]=useState(true)
    useEffect(() => {
        window.location.href=url
        setIsLoading(false)
    }, [])

    return (
        <Loader loading={isLoading} />
    )
};