import React, { Component } from 'react';
import Custom404 from '../Modules/Pages/Custom404/Custom404';

export default class Custom404Page extends Component {

    render() {
        return <Custom404 {...this.props} />
    }
}

