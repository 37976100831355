import React, { useState } from 'react';
import Field from '../Field/Field';
import CTAButton from '../CTAButton/CTAButton';
import './ErrorForm.scss';

const ReportForm = ({
    handleClose = () => {},
    handleSave = () => {}
}) => {
  const [data, setData] = useState({
      name: '',
      email: '',
      comment: ''
  })

  const onChange = (type, value) => {
    setData(prevState => {
        return {
            ...prevState,
            [type]: value
        }
    })
  }

  return(
    <div id='report-problem-content'>

        <div className="report-head_container">
            <a className="H6DesktopBlack report-head" onClick={(e) => handleClose(e)}>close</a>
        </div>

        <form onSubmit={(e)=> handleSave(e, data)}>

            <h2 className="H5DesktopGreen">Please fillout to report your problem.</h2>

            <Field
                className='field-group__field-full'
                type='email'
                label='Email'
                placeholder='Enter Email'
                value={data.email}
                required={true}
                handleChange={(val) => onChange('email', val)}
            />

            <Field
                className='field-group__field-full'
                type='text'
                label='Name'
                placeholder='Enter Name'
                value={data.name}
                required={true}
                handleChange={(val) => onChange('name', val)}
            />

            <Field
                className='field-group__field-full'
                type='textarea'
                label='What were you doing when the error occurred?'
                value={data.comment}
                handleChange={(val) => onChange('comment', val)}
                required={true}
                charLimit={2500}
            />

            <CTAButton id='login-page__cta' type='tertiary' >
               Submit
            </CTAButton>
        </form>
    </div>
  );
};


export default ReportForm;