import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Loader from '../../Components/Loader/Loader';
import { roleSwitch } from '../../Utility/ApplicationFormUtility';


// TODO: Add responsive style to ESIGN page
export default function SwitchRoleLoader({pageData, accountData}){

    const router = useRouter();
    const { scholarshipId } = router.query;
    const {heroku_role, userInfo} = pageData

    useEffect(() => {
      roleSwitch(scholarshipId, accountData.token, userInfo, (heroku_role || []).includes("Applicant"))
    }, []);

    return (
        <div className="callback-esign">
          <Loader loading={true}/>
        </div>

    )
}

