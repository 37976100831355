import React, { Component } from 'react';
import { MAIN_CONFIG } from '../../config/main';
import ApplicantScholarshipPage from '../../Modules/Pages/Applicant/ApplicantScholarshipPage/ApplicantScholarshipPage';
const { USER_CONTEXT } = MAIN_CONFIG;
import SwitchRoleLoader from '../../Modules/Pages/SwitchRoleLoader/SwitchRoleLoader';
import RedirectAdvertisementLink from '../../Modules/Components/RedirectAdvertisementLink/RedirectAdvertisementLink';
import Custom404Page from '../404';

export default class DonorApplicationsContainer extends Component {

    static async getInitialProps(context) {
        return ApplicantScholarshipPage.getInitialProps(context, MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICATIONS);
    }

    render() {
        const { userContext } = this.props;
        const {pageData} = this.props
        const isAdvertisementType= pageData?.scholarshipDetail?.scholarship?.form_type__c==="Advertisement"
        let url=pageData?.scholarshipDetail?.scholarship?.header_redirection_url__c
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
            case USER_CONTEXT.REVIEWER:
            case USER_CONTEXT.RECOMMENDER:
            case USER_CONTEXT.NGB:
            case USER_CONTEXT.SCHOOLCOUNSELLOR:
            case USER_CONTEXT.ENDORSEDUSER:
            case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
            case USER_CONTEXT.VERIFIER:
            case USER_CONTEXT.ADMIN:
                return <SwitchRoleLoader { ...this.props }/>
            case USER_CONTEXT.APPLICANT:
            case USER_CONTEXT.TEAMMEMBER:
            case USER_CONTEXT.LOGGED_OUT:
                if(!isAdvertisementType){
                    return <ApplicantScholarshipPage { ...this.props } />;
                }
                    return <RedirectAdvertisementLink url={url}/>
            default:
                return <Custom404Page {...this.props} message="Page not found, Please check your page URL and retry." />
        }
    }
}

